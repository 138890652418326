import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { CategoryComponent } from './category/category.component';
import { FamilyComponent } from './family/family.component';
import { ProductComponent } from './product.component';
import { SummaryComponent } from './summary/summary.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [
  { path: 'product', component: ProductComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'category'},
      { path: 'category', component: CategoryComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'family/:cat', component: FamilyComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'version/:cat/:fam', component: VersionComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'summary/:cat/:fam/:ver', component: SummaryComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {}
