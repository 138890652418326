import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit {
  catId: string = '';
  displayedData: any[];
  dataResult: any[];
  sortedData: any[];
  actualProdPath: string;
  loading = true;
  constructor(private g: GlobalService,private route: ActivatedRoute, private data: DataService, private message: NzMessageService, private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.catId = param.cat;
    });

    this.actualProdPath = this.g.getProdPath();
    this.data.getFamiliesByCat(this.catId).subscribe(
      result => {
        console.log(result)
        this.dataResult = result as any[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  back() {
    this.g.setProdPath(this.actualProdPath); 
    this.location.back();
  }

  forward(id, descrizione) {
    console.log('id', id, 'desc', descrizione);
    if (this.g.discounts[id]) {
      console.log('yes', this.g.discounts[id])
    } else {
      console.log('no', this.g.discounts[id])
    }
    this.actualProdPath += ' - ' + descrizione;
    this.g.setProdPath(this.actualProdPath);
    this.router.navigateByUrl('/product/version/' + this.catId + '/' + id);
  }

  filter(searchValue) {
    this.displayedData = this.dataResult.filter((item) => item.descrizione[this.g.selectedLanguage].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }

}
