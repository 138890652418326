import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { GlobalService } from '../shared/global.service';
@Injectable()

export class AuthService {
  private baseUrl: string;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  constructor(private myRoute: Router, private http: HttpClient, private g: GlobalService) {
    this.baseUrl = this.g.phpPath;
  }

  /**
  * @desc set a token in a sessionStorage variable
  * @param token -> token value
  * @return void
  */
 saveUser(user: string) {
  localStorage.setItem('LoggedInUser', user);
}

  /**
  * @desc get the token in sessionStorage
  * @param -
  * @return boolean: true if user is logged-in, false otherwise
  */
  getUser() {
    return JSON.parse(localStorage.getItem('LoggedInUser'));
  }


  /**
  * @desc get the token in sessionStorage
  * @param -
  * @return boolean: true if user is logged-in, false otherwise
  */
  isLoggedIn() {
    return this.getUser() !== null;
  }

  /**
  * @desc remove a token in a sessionStorage variable and navigate in login page
  * @param -
  * @return void
  */
  logout() {
    this.myRoute.navigate(['login']);
    this.isAuthenticated.next(false);
    localStorage.removeItem('LoggedInUser');
    this.g.resetQuote();
  }

  /** 
  * @desc call backend and check if inputed password is the same key storaged in database
  * @param formData -> contains inputed password
  * @return Observable<Error | any>, error if key is different, any otherwise
  */
  checkLogin(formData: FormData): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Users/login.php', formData, { headers: header }).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      }))
      ;
  }
}
