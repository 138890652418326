import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgZorroModule } from '../ng-zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProductComponent } from './product.component';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { ProductRoutingModule } from './product-routing.module';
import { CategoryComponent } from './category/category.component';
import { FamilyComponent } from './family/family.component';
import { VersionComponent } from './version/version.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { SummaryComponent } from './summary/summary.component';
import { AttributeModalComponent } from './summary/attribute-modal/attribute-modal.component';
import { RemovelastcharPipe } from 'src/app/pipes/removelastchar.pipe';
import { ImagePipe } from 'src/app/pipes/image.pipe';
import { PipesModule } from '../pipes.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ProductComponent, 
    CategoryComponent,
    FamilyComponent, 
    VersionComponent, 
    SummaryComponent, 
    AttributeModalComponent,
  ],
  imports: [
    ProductRoutingModule,
    CommonModule,
    BrowserModule,
    NgZorroModule,
    PipesModule,
    FormsModule,
    ScrollingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    })
  ],
  exports: [ProductComponent],
  providers: [AuthService, AuthGuard],
})
export class ProductModule { }
