import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {
  displayedData: any[];
  dataResult: any[];
  loading = true;
  uploadURL = this.g.phpPath + 'changeImages.php';
  activeTab = '';
  viewTab: string = "cat";

  constructor(private g: GlobalService, private data: DataService, private message: NzMessageService, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    console.log('view', this.viewTab)
    this.activeTab = this.viewTab;
    if (this.activeTab === 'cat') {
      this.data.getTable('categories').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (this.activeTab === 'fam') {
      this.data.getTable('families').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (this.activeTab === 'ver') {
      this.data.getTable('versions').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (this.activeTab === 'prods') {
      this.data.getTable('prods').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    }
  }

  changeTab(e) {
    this.loading = true;
    this.dataResult = [];
    this.displayedData = [];
    this.activeTab = e;
    if (e === 'cat') {
      this.data.getTable('categories').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (e === 'fam') {
      this.data.getTable('families').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (e === 'ver') {
      this.data.getTable('versions').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    } else if (e === 'prods') {
      this.data.getTable('prods').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          this.loading = false;
          console.error('GET TABLE: ', error);
        }
      );
    }
  }

  /*
  * @desc change icon action. save icon as "dataResult.code-icon" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customIconReq = (item) => {
    console.log('item', item)
    // Create a FormData here to store files and other parameters.
    const formData = new FormData()
    formData.append('file', item.file as any);
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  async handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') { }
    if (info.file.status === 'done') {
      console.log('reinit');
      this.viewTab = this.activeTab;
      this.ngOnInit();
    } else if (info.file.status === 'error') {
    } else if (info.file.status === 'removed') {
    }
  }

  /*
  * @desc check if uploaded image/icon is valid before insert into database and save
  * @param file -> uploaded image/icon
  * @return boolean, true if valid, false otherwise
  */
  beforeUpload = (file): boolean => {
    // file max size -> 2MB
    if (file.size > 2097152) {
      this.message.create('error', 'FILE SIZE: file size must be under 2MB', { nzDuration: 5000 });
      return false;
    }
    return true;
  }

  filter(searchValue) {
    this.displayedData = this.dataResult.filter((item) => item.nome[this.g.selectedLanguage].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }

  filterVer(searchValue) {
    this.displayedData = this.dataResult.filter((item) => item.descrizioe[this.g.selectedLanguage].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }
}
