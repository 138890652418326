import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TblProdotti } from 'src/app/entities/TblProdotti';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { Location } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AttributeModalComponent } from '../product/summary/attribute-modal/attribute-modal.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  rowId: string = '';
  row: any;
  loading = true;
  attrsData: any[];
  dataResult: { [id: string]: { descrizione: string, data: TblProdotti[], selezionato: TblProdotti, attivo: boolean } } = {};
  displayedData: { [id: string]: { descrizioe: string, data: TblProdotti[], selezionato: TblProdotti, attivo: boolean } } = {};
  multiplyIndex = 1;
  actualProdPath: string;

  constructor(private g: GlobalService, private route: ActivatedRoute, private data: DataService, private router: Router, private location: Location,
    private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.rowId = param.id;
    });

    const formData = new FormData();
    formData.append('id', this.rowId);
    this.data.getQuoteRowById(formData).subscribe(
      result => {
        this.row = result[0];
        const formData2 = new FormData();
        formData2.append('cat', this.row.id_cat);
        formData2.append('fam', this.row.id_fam);
        formData2.append('ver', this.row.id_ver);
        this.data.getAttrsByVer(formData2).subscribe(
          result => {
            this.attrsData = result as any[];
            this.attrsData.forEach((att, index, array) => {
              const formData2 = new FormData();
              formData2.append('fam', this.row.id_fam);
              formData2.append('ver', this.row.id_ver);
              formData2.append('attr', att.id);
              this.data.getProducts(formData2).subscribe(
                result2 => {
                  let defaultSelected: TblProdotti = result2.filter(obj => {
                    let rowsAttr: any[] = this.row.attrs;
                    return rowsAttr.filter(att => {
                      return obj.id === att.id_attr
                    })[0];
                  })[0];

                  let rowAtt = this.row.attrs.filter(aRow => {
                    return aRow.id_attr === defaultSelected.id;
                  })[0];

                  defaultSelected.id = defaultSelected.id.split('@')[0];

                  this.dataResult[att.id] = {
                    descrizione: att.descrizione,
                    data: result2,
                    selezionato: defaultSelected,
                    attivo: rowAtt.attivo
                  };
                  if (index === array.length - 1) {
                    this.loading = false;
                  }
                },
                error => {
                  this.loading = false;
                  console.error('GET TABLE: ', error);
                }
              );
            });
          },
          error => {
            console.log(error);
          });

      }, error => {
        console.log(error);
      });
  }

  addQ(data, n) {
    if (this.dataResult[data].selezionato.qta + (this.dataResult[data].selezionato.qta_suggerita * n) >= 0) {
      this.dataResult[data].selezionato.qta = this.dataResult[data].selezionato.qta + this.dataResult[data].selezionato.qta_suggerita * n;
    }
  }

  back() {
    this.location.back();
  }

  changeAttirbute(id) {
    this.loading = true;
    const data = this.dataResult[id].data;
    const modal = this.modal.create({
      nzTitle: 'Scegli attributo',
      nzContent: AttributeModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: 'uiAttributeModal',
      nzFooter: null,
      nzComponentParams: {
        data: data,
      },
      nzOnOk: () => {
        this.dataResult[id].selezionato = JSON.parse(this.g.getSelectedAttribute());
        this.dataResult[id].selezionato.qta = this.dataResult[id].selezionato.qta_suggerita * this.multiplyIndex;
        this.loading = false;
      },
      nzOnCancel: () => {
        console.log('cancel modal');
        this.loading = false;
      }
    });
  }

  multiply() {
    this.attrsData.forEach(element => {
      let id = element.id;
      this.dataResult[id].selezionato.qta = this.dataResult[id].selezionato.qta_suggerita * this.multiplyIndex;
    });
  }

  addQModal(n) {
    if (this.multiplyIndex + n >= 0)
      this.multiplyIndex = this.multiplyIndex + n;
  }

  editQuoteRow() {
    let attrArray: any[] = [];
    this.attrsData.forEach(element => {
      let id = element.id;
      attrArray.push({
        id_attr: this.dataResult[id].selezionato.id + '@' + this.row.id_fam + "|" + this.row.id_ver + "|" + id,
        genere: element.descrizione,
        descrizione: this.dataResult[id].selezionato.nome,
        prezzo: this.dataResult[id].selezionato.prezzo,
        qta: this.dataResult[id].selezionato.qta,
        qta_suggerita: this.dataResult[id].selezionato.qta_suggerita,
        attivo: this.dataResult[id].attivo
      });
    });
    const formData = new FormData();
    formData.append('id', this.rowId);
    formData.append('attrs', JSON.stringify(attrArray));

    this.data.editQuoteRow(formData).subscribe(
      result => {
        let quote = this.g.getQuote();
        quote.prezzo = 0;
        console.log('data', attrArray);
        attrArray.forEach(element => {
          if (element.attivo) {
            quote.prezzo += parseInt(element.prezzo) * element.qta;
            this.g.saveQuote(JSON.stringify(quote));
          }
        });
        this.location.back();
      },
      error => {
        console.log(error);
      });
  }

  hideAtt(item) {
    this.dataResult[item.id].attivo = !this.dataResult[item.id].attivo;
  }
}

