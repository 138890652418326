import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  displayedData: any[];
  dataResult: any[];

  loading = true;

  constructor(private g: GlobalService, private data: DataService, private message: NzMessageService, private router: Router) { }

  ngOnInit(): void {
    this.data.getTable('categories').subscribe(
      result => {
        console.log(result)
        this.dataResult = result as any[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
   ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'addCat';
    });
  }

  forward(id, descrizione) {
    this.g.productPath = descrizione;
    this.g.setProdPath(this.g.productPath);
    this.router.navigateByUrl('/product/family/' + id);
  }

  filter(searchValue) {
    this.displayedData = this.dataResult.filter((item) => item.descrizione[this.g.selectedLanguage].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }
}
