
import { NgModule } from '@angular/core';
import { ImagePipe } from '../pipes/image.pipe';
import { ImageExistPipe } from '../pipes/imageExist.pipe';
import { RemovelastcharPipe } from '../pipes/removelastchar.pipe';

@NgModule({
  declarations: [
    ImagePipe,
    ImageExistPipe,
    RemovelastcharPipe
  ],
  exports: [
    ImagePipe,
    ImageExistPipe,
    RemovelastcharPipe
  ]
})
export class PipesModule {}
