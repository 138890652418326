import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PipeTransform, Pipe } from "@angular/core";
import { Observable, Subject, Subscriber } from "rxjs";
import { GlobalService } from "../shared/global.service";

@Pipe({ name: "image" })
export class ImagePipe implements PipeTransform {
  defaultImage: string = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==";

  constructor(private http: HttpClient, private g: GlobalService) { }
  private header = new HttpHeaders();

  transform(url: string): Observable<any> {
    var subject = new Subject<string>();
    let fixUrl = url.split('/')[0];
    let completeUrl = this.g.imgPath + fixUrl;
    let firstUrl = completeUrl + '.JPG';
    // url.JPG
    this.getUrl(firstUrl).subscribe(
      (res) => {
        if (res) {
          subject.next(firstUrl);
        } else {
          // url.jpg
          let secondUrl = completeUrl + '.jpg';
          this.getUrl(secondUrl).subscribe(
            (res2) => {
              if (res2) {
                subject.next(secondUrl);
              } else {
                if (completeUrl.length > 1) {
                  // ur.JPG
                  let thirdUrl = completeUrl.substring(0, completeUrl.length - 1) + '.JPG';
                  this.getUrl(thirdUrl).subscribe(
                    res3 => {
                      if (res3) {
                        subject.next(thirdUrl);
                      } else {
                        // ur.jpg
                        let fourthUrl = completeUrl.substring(0, completeUrl.length - 1) + '.jpg';
                        this.getUrl(fourthUrl).subscribe(
                          res4 => {
                            if (res4) {
                              subject.next(fourthUrl);
                            } else {
                              if (completeUrl.length > 2) {
                                // u.JPG
                                let fifthUrl = completeUrl.substring(0, completeUrl.length - 2) + '.JPG';
                                this.getUrl(fifthUrl).subscribe(
                                  res5 => {
                                    if (res5) {
                                      subject.next(fifthUrl);
                                    } else {
                                      //u.jpg
                                      let sixthUrl = completeUrl.substring(0, completeUrl.length - 2) + '.jpg';
                                      this.getUrl(sixthUrl).subscribe(
                                        res5 => {
                                          if (res5) {
                                            subject.next(sixthUrl);
                                          } else {
                                            subject.next(this.g.imgPath + 'default.jpg');
                                          }
                                        }
                                      );
                                    }
                                  }
                                );
                              } else {
                                subject.next(this.g.imgPath + 'default.jpg');
                              }
                            }
                          }
                        );
                      }
                    }
                  );
                } else {
                  subject.next(this.g.imgPath + 'default.jpg');
                }
              }
            }
          )
        }
      }
    );
    return subject.asObservable();
  }

  getUrl(url): Observable<boolean> {
    return new Observable((observer) => {
      try {
        var xhr = new XMLHttpRequest();
        xhr.getResponseHeader("content-type");
        xhr.open('GET', url, true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function (oEvent) {
          if (xhr.readyState === 4) {
            if (xhr.status !== 200) {
              observer.next(false);
              observer.complete();
            } else {
              if (xhr.response.type === 'image/jpeg') {
                observer.next(true);
                observer.complete();
              }
              else {
                observer.next(false);
                observer.complete();
              }
            }
          }
        };
        xhr.send();
      } catch (e) {
        observer.next(false);
        observer.complete();
      }
    });
  }
}



// RewriteBase /
// RewriteRule ^index\.html$ - [L]
// RewriteCond %{REQUEST_FILENAME} !-f
// RewriteCond %{REQUEST_FILENAME} !-d
// RewriteRule . /index.html [L]