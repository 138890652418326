import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { Location } from '@angular/common';
import { TblProdotti } from 'src/app/entities/TblProdotti';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AttributeModalComponent } from './attribute-modal/attribute-modal.component'

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})


export class SummaryComponent implements OnInit {
  catId: string = '';
  famId: string = '';
  verId: string = '';
  attrsData: any[];
  dataResult: { [id: string]: { descrizione: string, data: TblProdotti[], selezionato: TblProdotti, attivo: true } } = {};
  displayedData: { [id: string]: { descrizioe: string, data: TblProdotti[], selezionato: TblProdotti, attivo: true } } = {};
  showMultiply = false;
  //displayedData: any[] = [];
  //dataResult: any[] = [];
  sortedData: any[];
  multiplyIndex = 1;
  actualProdPath: string;

  loading = true;

  constructor(private viewContainerRef: ViewContainerRef, private modal: NzModalService, private g: GlobalService, private route: ActivatedRoute, 
    private data: DataService, private message: NzMessageService, private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.catId = param.cat;
      this.famId = param.fam;
      this.verId = param.ver;
    });

    this.actualProdPath = this.g.getProdPath();
    const formData = new FormData();
    formData.append('cat', this.catId);
    formData.append('fam', this.famId);
    formData.append('ver', this.verId);

    this.data.getAttrsByVer(formData).subscribe(
      result => {
        this.attrsData = result as any[];
        this.attrsData.forEach((att, index, array) => {
          const formData2 = new FormData();
          formData2.append('fam', this.famId);
          formData2.append('ver', this.verId);
          formData2.append('attr', att.id);
          this.data.getProducts(formData2).subscribe(
            result2 => {
              let defaultSelected: TblProdotti = result2.filter(obj => {
                return obj.posizione === 1
              })[0];
              defaultSelected.id = defaultSelected.id.split('@')[0];
              this.dataResult[att.id] = {
                descrizione: att.descrizione,
                data: result2,
                selezionato: defaultSelected,
                attivo: true
              };
              if (index === array.length - 1) {
                this.loading = false;
                console.log('ATTRS: ', this.attrsData);
                console.log('DATA: ', this.dataResult)
              }
            },
            error => {
              this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
              this.loading = false;
              console.error('GET TABLE: ', error);
            }
          );
        });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  addQ(data, n) {
    if ((this.dataResult[data].selezionato.qta) + (this.dataResult[data].selezionato.qta_suggerita * n) >= 0)
      this.dataResult[data].selezionato.qta = this.dataResult[data].selezionato.qta + (this.dataResult[data].selezionato.qta_suggerita * n);
  }

  back() {
    let split = this.actualProdPath.split(':')[0];
    this.g.setProdPath(split);
    this.location.back();
  }


  changeAttirbute(id) {
    this.loading = true;
    const data = this.dataResult[id].data;
    const modal = this.modal.create({
      nzTitle: 'Scegli attributo',
      nzContent: AttributeModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: 'uiAttributeModal',
      nzFooter: null,
      nzComponentParams: {
        data: data,
      },
      nzOnOk: () => {
        this.dataResult[id].selezionato = JSON.parse(this.g.getSelectedAttribute());
        this.dataResult[id].selezionato.qta = this.dataResult[id].selezionato.qta_suggerita * this.multiplyIndex;
        this.loading = false;
      },
      nzOnCancel: () => {
        console.log('cancel modal');
        this.loading = false;
      }
    });
  }

  multiply() {
    this.attrsData.forEach(element => {
      let id = element.id;
      this.dataResult[id].selezionato.qta = this.dataResult[id].selezionato.qta_suggerita * this.multiplyIndex;
    });
  }

  addQModal(n) {
    if (this.multiplyIndex + n >= 0)
      this.multiplyIndex = this.multiplyIndex + n;
  }

  addProdToQuote() {
    console.log('DATAR', this.dataResult)
    let attrArray: any[] = [];
    let price: number = 0;
    this.attrsData.forEach(element => {
      let id = element.id;
      console.log(this.dataResult[id]);
      price = price + parseFloat((this.dataResult[id].selezionato.prezzo * this.dataResult[id].selezionato.qta).toString());
        attrArray.push({
          id_attr: this.dataResult[id].selezionato.id + '@' + this.famId + "|" + this.verId + "|" + id,
          genere: element.descrizione,
          descrizione: this.dataResult[id].selezionato.nome,
          prezzo: this.dataResult[id].selezionato.prezzo,
          qta: this.dataResult[id].selezionato.qta,
          qta_suggerita: this.dataResult[id].selezionato.qta_suggerita,
          attivo: this.dataResult[id].attivo
        });
    });

    const formData = new FormData();
    formData.append('id_prev', this.g.getQuote().id);
    formData.append('cat', this.catId);
    formData.append('fam', this.famId);
    formData.append('ver', this.verId);
    formData.append('attrs', JSON.stringify(attrArray));

    this.data.addQuoteRow(formData).subscribe(
      result => {
        console.log(result);
        let quote = this.g.getQuote();
        quote.qta += 1;
        quote.prezzo += price;
        this.g.saveQuote(JSON.stringify(quote));
        this.router.navigateByUrl('quote/' + quote.id);
      }, 
      error => {
        console.log(error);
      });
  }

  onChangeNumber(e, s) {
      let rest = e%(s.qta_suggerita);
      if (rest > 0) {
        s.qta = s.qta - rest;
      }
  }
}
