import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  lastAvailable;
  showModal = false;
  constructor(private g: GlobalService, private auth: AuthService, private data: DataService, private router: Router,
    private translate: TranslateService) { }


  ngOnInit() {
    // check if USER HAVE A "IN PROGRESS" QUOTE
    const formDataQuote = new FormData();
    formDataQuote.append('email', this.auth.getUser().email);
    this.data.checkLastQuote(formDataQuote).subscribe(
      result => {
        this.lastAvailable = result;
      },
      error => {
        console.error('Unable get last quote: ', error);
      }
    );

    const formDataGroups = new FormData();
      formDataGroups.append('codice', this.auth.getUser().codice);
      this.data.checkDiscounts(formDataGroups).subscribe(
        result => {
          console.log('sconti', result);
          console.log('code', this.auth.getUser().codice)
          this.g.discounts = result;
        },
        error => {
          console.error('Unable get last quote: ', error);
        }
      )      
  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'homepage';
    });
  }

  /**
    * @desc create a new quote.
    *   IF THE USER HAVE NOT A "IN PROGRESS" QUOTE: save the new quote in localstorage and navigate to /product 
    *   OTHERWISE: show modal
    * @param -
    * @return void
  */
  async createNew() {
    if (!this.lastAvailable) {
      const checkSumKey = await this.g.encryptDataGlobal(this.auth.getUser().email + this.auth.getUser().rag_soc);
      const formData = new FormData();
      formData.append('email', this.auth.getUser().email);
      formData.append('rag_soc', this.auth.getUser().rag_soc);
      formData.append('data_creazione', Date.now().toString());
      formData.append('checkKey', checkSumKey);
      this.data.addQuote(formData).subscribe(
        result => {
          this.g.saveQuote(JSON.stringify(result));
          this.router.navigateByUrl('product');
        },
        error => {
          console.error('Unable to add: ', error);
        }
      );
    } else {
      this.showModal = true;
    }
  }

  /**
    * @desc the user have a "IN PROGRESS" quote and want to show it.
    *       navigate to /quote:id
    * @param -
    * @return void
  */
  continueQuote() {
    const formDataQuote = new FormData();
    formDataQuote.append('id_prev', this.lastAvailable);
    this.data.getQuoteRows(formDataQuote).subscribe(
      result => {
        let rows = result;
        let price = 0;
        let qta = 0;
        if (rows.length > 0) {
          rows.forEach((row, index, array) => {
            console.log(row);
            qta += 1;
            row.attrs.forEach((att, index, array) => {
              price += parseInt(att.prezzo) * att.qta;
            });
            if (index === array.length - 1) {
              let json = { id: this.lastAvailable, prezzo: price, qta: qta };
              this.g.saveQuote(JSON.stringify(json));
              this.router.navigateByUrl('quote/' + this.lastAvailable);
            }
          })
        } else {
          let json = { id: this.lastAvailable, prezzo: price, qta: qta };
          this.g.saveQuote(JSON.stringify(json));
          this.router.navigateByUrl('quote/' + this.lastAvailable);
        }

      },
      error => {
        console.error('Unable get last quote: ', error);
      }
    )
  }

  /**
    * @desc the user have a "IN PROGRESS" quote and want to delete it.
    *       set deleted state to "IN PROGRESS" quote and add Log.
    *       create a new one. Save it in localstorage and navigate to /product.
    * @param -
    * @return void
  */
  async onSubmitModal() {
    // stato cancellato del precedente preventivo
    const checkSumKey = await this.g.encryptDataGlobal('TblPreventivi' + this.lastAvailable);
    const formData = new FormData();
    formData.append('id', this.lastAvailable);
    formData.append('field', 'stato');
    formData.append('stato', '2');
    formData.append('checkKey', checkSumKey);
    this.data.editQuote(formData).subscribe(
      result => {
        // add log
        const formDataLog = new FormData();
        formDataLog.append('email', this.auth.getUser().email);
        formDataLog.append('rag_soc', this.auth.getUser().rag_soc);
        formDataLog.append('dataora', Date.now().toString());
        formDataLog.append('operation', 'Annullamento preventivo ' + this.lastAvailable);
        this.data.addLog(formDataLog).subscribe(
          async result => {
            // add quote
            const checkSumKey2 = await this.g.encryptDataGlobal(this.auth.getUser().email + this.auth.getUser().rag_soc);
            const formData2 = new FormData();
            formData2.append('email', this.auth.getUser().email);
            formData2.append('rag_soc', this.auth.getUser().rag_soc);
            formData2.append('data_creazione', Date.now().toString());
            formData2.append('checkKey', checkSumKey2);
            this.data.addQuote(formData2).subscribe(
              result => {
                this.lastAvailable = result.id.toString();
                //this.g.saveQuote(result.toString());
                this.g.saveQuote(JSON.stringify(result));
                this.router.navigateByUrl('product');
              },
              error => {
                console.error('Unable to add: ', error);
              }
            );
          },
          error => {
            console.error('Unable Log: ', error);
          }
        );
      },
      error => {
        console.error('EDIT QUOTE: ', error);
      }
    );
  }
}
