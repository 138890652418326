import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AdminComponent } from './admin.component';
import { ImagesComponent } from './images/images.component';
import { LogsComponent } from './logs/logs.component';
import { QuotesComponent } from './quotes/quotes.component';
import { UsersComponent } from './users/users.component';



const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'users'},
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'logs', component: LogsComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'quotes', component: QuotesComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'images', component: ImagesComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
