import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { AdminRoutingModule } from './pages/admin/admin-routing.module';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductRoutingModule } from './pages/product/product-routing.module';
import { EditComponent } from './pages/edit/edit.component';
import { QuoteComponent } from './pages/quote/quote.component';
import { SignupComponent } from './pages/signup/signup.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard], canActivateChild: [LoginGuard]},
  { path: 'signup', component: SignupComponent, canActivate: [LoginGuard], canActivateChild: [LoginGuard]},
  { path: 'homepage', component: HomepageComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'quote/:id', component: QuoteComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'edit/:id', component: EditComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: '**', pathMatch: 'full', redirectTo: '/homepage'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AdminRoutingModule, ProductRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
