import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removelastchar'
})
export class RemovelastcharPipe implements PipeTransform {

  transform(value: string): any {
    return value.substring(0, value.length - 1);
  }

}
