import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  
  quote: any;

  constructor(private g: GlobalService) { }

  ngOnInit(): void {
    this.quote = this.g.getQuote();
  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
   ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'addProd';
    });
  }
}
