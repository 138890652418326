import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TblLog } from 'src/app/entities/TblLog';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  sortName: string | null = null;
  sortValue: string | null = null;
  searchEmail = '';
  visibleSearch = false;
  dataResult: TblLog[];
  displayedLog: TblLog[];
  loading = true;
  constructor(private data: DataService, private message: NzMessageService, private g: GlobalService) { }

  ngOnInit() {
    //get all Logs
    this.data.getTable('logs').subscribe(
      result => {
        this.dataResult = result as TblLog[];
        this.displayedLog = [
          ...this.dataResult
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  /** 
  * @desc sort Logs based on Log.dataora
  * @param a - first Log
  * @param b - second Log
  * @return void
  */
  sort = (a: TblLog, b: TblLog) => a.dataora - b.dataora;

  /** 
  * @desc filter Logs based on Log.email
  * @param
  * @return void
  */
  find(): void {
    this.visibleSearch = false;
    this.displayedLog = this.dataResult.filter((item: TblLog) => item.email.indexOf(this.searchEmail) !== -1);
  }

  /** 
  * @desc reset search
  * @param -
  * @return void
  */
  reset(): void {
    this.searchEmail = '';
    this.find();
  }

}
