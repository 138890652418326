import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { CronService } from './services/cron.service';
import { DataService } from './services/data.service';
import { GlobalService } from './shared/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'metalseat-quote';
  constructor(private g: GlobalService, private auth: AuthService, private translate: TranslateService, private data: DataService,
    private router: Router, private cron: CronService) {
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang('it');
    translate.use('it');

    this.data.getKey().subscribe(
      result => {
        for (let i = 0; i < result.length; i++) {
          this.g.saveKey(result[i].key);
          console.log('KEY', result[i].key);
        }
      },
      error => {
        console.error('GET KEY: ', error);
      });

    if (this.auth.isLoggedIn()) {
      const formDataGroups = new FormData();
      formDataGroups.append('codice', this.auth.getUser().codice);
      this.data.checkDiscounts(formDataGroups).subscribe(
        result => {
          console.log(result);
          this.g.discounts = result;
        },
        error => {
          console.error('Unable get last quote: ', error);
        }
      )
    } else {
      console.log('not logged in')
    }

    //this.cron.addCron();
  }

  /**
      * @desc change system language
      * @param language - the system language
      * @return void
  */
  useLanguage(language: string) {
    this.translate.use(language);
    this.g.selectedLanguage = language;
  }

  /**
    * @desc navigate to location
    * @param location - the location to navigate on
    * @return void
  */
  goTo(location: string) {
    this.router.navigateByUrl(location);
  }
}
