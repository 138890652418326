import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  catId: string = '';
  famId: string = '';
  displayedData: any[];
  actualProdPath: string;
  dataResult: any[];
  sortedData: any[];

  loading = true;

  constructor(private g: GlobalService, private route: ActivatedRoute, private data: DataService, private message: NzMessageService, private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.catId = param.cat;
      this.famId = param.fam;
    });

    this.actualProdPath = this.g.getProdPath();

    const formData = new FormData();
    formData.append('cat', this.catId);
    formData.append('fam', this.famId);

    this.data.getVersionsByFam(formData).subscribe(
      result => {
        console.log(result)
        this.dataResult = result as any[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  back() {
    let split = this.actualProdPath.split(' - ')[0];
    this.g.setProdPath(split); 
    this.location.back();
  }

  forward(id, descrizione) {
    let path = this.g.getProdPath();
    path += ': ' + descrizione;
    this.g.setProdPath(path);
    this.router.navigateByUrl('/product/summary/' + this.catId + '/' + this.famId + '/' + id);
  }

  filter(searchValue) {
    this.displayedData = this.dataResult.filter((item) => item.descrizione[this.g.selectedLanguage].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }
}
