import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SignupComponent } from './pages/signup/signup.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { QuoteComponent } from './pages/quote/quote.component';
import { AdminModule } from './pages/admin/admin.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { ProductModule } from './pages/product/product.module';
import { NgZorroModule } from './pages/ng-zorro.module';
import { EditComponent } from './pages/edit/edit.component';
import { ImagePipe } from './pipes/imageQuote.pipe';
import { PipesModule } from './pages/pipes.module';


registerLocaleData(it);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomepageComponent,
    QuoteComponent,
    EditComponent,
    ImagePipe,
  ],
  imports: [
    AppRoutingModule,
    AdminModule,
    ProductModule,
    BrowserModule,
    NgZorroModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    })
  ],
  providers: [AuthService, AuthGuard, { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})
export class AppModule { }
