import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-attribute-modal',
  templateUrl: './attribute-modal.component.html',
  styleUrls: ['./attribute-modal.component.scss']
})
export class AttributeModalComponent implements OnInit {
  @Input() data: any;

  displayedData: any[] = [];
  constructor(private g: GlobalService, private modal: NzModalRef) { }

  ngOnInit(): void {
    this.data.forEach(element => {
      let elementSplit = element;
      elementSplit.id = elementSplit.id.split('@')[0];
      this.displayedData.push(elementSplit);
    });
    console.log(this.displayedData);
  }

  select(item) {
    this.g.saveSelectedAttribute(JSON.stringify(item));
    this.modal.triggerOk();
    this.modal.destroy();
  }

  back() {
    this.modal.triggerCancel();
  }

}
