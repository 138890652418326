import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  isCollapsed = false;
  constructor(private auth: AuthService, private dataService: DataService,
    private g: GlobalService) { }

  ngOnInit() {
  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'admin';
    });
  }
}
