import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from 'src/app/shared/global.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  CryptoJS = require('crypto-js');

  appVersion: string;
  password: string;
  username: string;
  showError: boolean;
  constructor(private router: Router, private auth: AuthService, private g: GlobalService, private translate: TranslateService,
    private data: DataService) { }

  ngOnInit() {
    this.password = '';
    this.username = '';
    this.showError = false;
  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'login';
    });
  }

  /**
    * @desc check if typed credentials are corrects.
      IF CORRECT: return all user's fields and save it in localstorage.
        IF ADMIN: navigate to /admin
        IF NOT ADMIN: navigate to /homepage and add Log
      OTHERWISE: show error
    * @param -
    * @return void
  */
  async onSubmit() {
    const crypt = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.password));
    /*
    var words = this.CryptoJS.enc.Base64.parse(base64);
    var textString = this.CryptoJS.enc.Utf8.stringify(words);
    console.log('u', textString);
    */
    const formData = new FormData();
    formData.append('pwd', crypt);
    formData.append('user', this.username);
    this.auth.checkLogin(formData).subscribe(
      result => {
        this.auth.isAuthenticated.next(true);
        // this.g.loggedUser = result as User;
        this.auth.saveUser(JSON.stringify(result.user));
        if (result.user.admin) {
          this.router.navigateByUrl('/admin');
        } else {
          // add log
          const formDataLog = new FormData();
          formDataLog.append('email', this.auth.getUser().email);
          formDataLog.append('rag_soc', this.auth.getUser().rag_soc);
          formDataLog.append('dataora', Date.now().toString());
          formDataLog.append('operation', 'Login');
          this.data.addLog(formDataLog).subscribe(
            result => {
              this.router.navigateByUrl('/homepage');
            },
            error => {
              console.error('Unable Log: ', error);
            }
          );
        }
      },
      error => {
        this.showError = true;
        console.error('LOGIN: ', error);
      }
    );
  }

  /**
    * @desc navigate to location
    * @param location - the location to navigate on
    * @return void
  */
  goTo(location: string) {
    this.router.navigateByUrl(location);
  }
}
