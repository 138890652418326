import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { TblPreventivi } from 'src/app/entities/TblPreventivi';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  CryptoJS = require('crypto-js');

  sortName: string | null = null;
  sortValue: string | null = null;
  searchEmail = '';
  visibleSearch = false;
  dataResult: TblPreventivi[];
  sortedData: TblPreventivi[];
  displayedData: TblPreventivi[];
  listOfSearchName: string[] = [];
  loading = true;
  listOfStatus: any[] = [{ value: 0, text: 'In corso' }, { value: 1, text: 'Confermato' },
  { value: 2, text: 'Cancellato' }];
  searchStatus: number;

  constructor(private data: DataService, private message: NzMessageService, private g: GlobalService) { }

  ngOnInit() {
    //get all Quotes
    this.data.getTable('quotes').subscribe(
      result => {
        this.dataResult = result as TblPreventivi[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.sortedData = [
          ...this.displayedData
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  /** 
  * @desc filter Quotes based on Quote.stato
  * @param list - list of available status
  * @param item - Quote to filter
  * @return void
  */
  statusFilter = (list: number[], item: TblPreventivi) => list.some(status => item.stato === status);

  /** 
  * @desc reset search
  * @param -
  * @return void
  */
  reset(): void {
    this.searchEmail = '';
    this.find();
  }

  /** 
  * @desc filter Quotes based on Quote.email
  * @param -
  * @return void
  */
  find(): void {
    this.visibleSearch = false;
    this.displayedData = this.dataResult.filter((item: TblPreventivi) => item.email.indexOf(this.searchEmail) !== -1);
  }

  async download(id) {
    var url = this.g.phpPath + "preventivi/quote-" + id + '.pdf';
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = 'quote-' + id + '.pdf';
    a.target = "_blank";
    a.click();
  }
}
