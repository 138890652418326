import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../shared/global.service';

@Injectable({
  providedIn: 'root'
})
export class CronService {
  private baseUrl: string;
  private header = new HttpHeaders();

  constructor(private http: HttpClient, private globalService: GlobalService) {
    this.baseUrl = this.globalService.phpPath;
  }

  addCron() {
    var that = this;
    var CronJob = require('cron').CronJob;
    const job = new CronJob('0 * * * * *', function () {
      const d = new Date();
      that.syncronizeTable().subscribe(
        result => {
          const formData = new FormData();
          formData.append('date', new Date().toString());
          formData.append('log', 'SUCCESS CRONJOB');
          that.addCronLog(formData).subscribe(
            result => {
            }, 
            error => {
              console.error('LOG CRON ERROR', error);
            }
          );
        },
        error => {
          const formData = new FormData();
          formData.append('date', new Date().toString());
          formData.append('log', 'CRONJOB ERROR: ' + error.detail.text);
          that.addCronLog(formData).subscribe(
            result => {
            }, 
            error => {
              console.error('LOG CRON ERROR', error);
            }
          );
        }
      );;
      console.log('log:', d.toString());
    });
    job.start();
  }

  syncronizeTable(): Observable<Error | any> {
    console.log('SYNC');
    this.header.append('Content-Type', 'application/json');
    return this.http.get<any>(this.baseUrl + '/Cron/cron.php', { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  addCronLog(formData: FormData) {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Cron/addCronLog.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }
}

